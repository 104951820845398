<div class="modal modal-summary" ng-class="{'fade': !isMobile}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close fui-cross" data-dismiss="modal"></button>
                <h4 class="modal-title">Summary&nbsp;<save-status></save-status></h4>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <design-summary></design-summary>
                </div>
            </div>
            <div class="modal-footer" ng-class="{'blur grayscale': saveStatus === 'warning'}">

                <share-buttons></share-buttons>

                <label class="pull-left actions-label hidden-sm hidden-md hidden-lg">Actions</label>
                <button class="btn btn-sm btn-primary btn-print pull-right" ng-click="print()" ng-disabled="!printEnabled">
                    <span class="fui-document" ng-class="{'loading loading-inline': !printEnabled}"></span>
                    <span ng-if="!printEnabled">Preparing print</span>
                    <span ng-if="printEnabled">Print</span>
                </button>

            </div>
        </div>
    </div>
</div>
