<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="btn-block btn-group btn-group-vertical">

        <menu-button menu-id="jerseyChoice" button-text="Jersey Choice">
        </menu-button>

        <menu-button menu-id="design" button-text="Design" ng-disabled="!designDataUtils.isSubmenuAvailable('design')">
        </menu-button>

        <menu-button menu-id="piping" button-text="Piping" ng-disabled="!designDataUtils.isSubmenuAvailable('piping') && !designDataUtils.canHavePiping()">
        </menu-button>

        <menu-button menu-id="colors" button-text="Colors" ng-disabled="!designDataUtils.isSubmenuAvailable('colors')">
        </menu-button>

    </div>

    <div class="btn-block btn-group btn-group-vertical">

        <menu-button menu-id="frontLogo" button-text="Front Logo" ng-disabled="!designDataUtils.isSubmenuAvailable('frontLogo')">
        </menu-button>

        <menu-button menu-id="sleeveLogo" button-text="Sleeve Logo" ng-disabled="!designDataUtils.isSubmenuAvailable('sleeveLogo')">
        </menu-button>

        <menu-button menu-id="teamName" button-text="Team Name" ng-disabled="!designDataUtils.isSubmenuAvailable('teamName')">
        </menu-button>

    </div>

    <div class="btn-block btn-group btn-group-vertical">

        <menu-button menu-id="playerName" button-text="Player Name" ng-disabled="!designDataUtils.isSubmenuAvailable('playerName')">
        </menu-button>

        <menu-button menu-id="number" button-text="Number" ng-disabled="!designDataUtils.isSubmenuAvailable('number')">
        </menu-button>

        <menu-button menu-id="roster" button-text="Roster">
        </menu-button>

    </div>

</div>
