<ul class="row list-unstyled">
    <li ng-if="enableProperty"><!-- todo: what is this -->
        <button class="button button_block button_tile" ng-class="{'selected': (enableProperty.value() === 'off')}" ng-click="disable()">None</button>
    </li>

    <li ng-class="{
            'col-xs-12': itemList.length === 1,
            'col-xs-6': itemList.length !== 1
        }" ng-repeat="itemCode in itemList">
        <tile ng-click="selectItem(itemCode)"></tile>
    </li>

    <li ng-if="expandable"><!-- todo: what is this -->
        <div class="tiles__tile tiles__tile_transparent" ng-click="toggleExpand()">
            <div class="tiles__item tiles__item_1x1 tiles__item_expand">
                <span class="tiles__text tiles__text_middle">
                    <span ng-if="!expanded">More&hellip;</span>
                    <span ng-if="expanded">Less&hellip;</span>
                </span>
            </div>
        </div>
    </li>
</ul>
