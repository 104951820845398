<form class="uploader form-group" target="factory-uploader-fallback" action="{{path}}" method="post" enctype="multipart/form-data" ng-class="{'uploader-legacy': !supported}">
    <div class="input-group btn-block">

        <input class="uploader-input" type="file" name="image" size="1" accept="*/*" title="Select file...">

        <div class="uploader-progress-bar" ng-hide="(status !== 'processing')"></div>

        <div class="input-group">
            <input class="form-control uploader-input-ghost" onfocus="this.blur()" type="text" value="{{fileName || 'Select file...'}}">
            <div class="input-group-btn">
                <a class="btn btn-primary uploader-btn">Browse</a>
            </div>
        </div>

    </div>

    <div class="text-info" ng-show="status === 'processing'">
        <div class="informative">Uploading file. Please wait.</div>
    </div>

    <div class="text-danger" ng-show="status === 'error'">
        <div class="informative">Problem occurred while uploading file. Please try again.</div>
    </div>

    <div class="text-success" ng-show="status === 'success'">
        <div class="informative">File successfully uploaded.</div>
    </div>

    <div><div class="informative">To streamline inquiry process please provide high resolution file ready for print.</div></div>

    <iframe name="factory-uploader-fallback" src="about:blank" width="0" height="0"></iframe>

</form>
