
<div class="share-buttons">

    <label>Share with</label>

    <a class="btn btn-block btn-social-twitter" ng-click="share('twitter')" analytics-on="click" analytics-category="User Interaction" analytics-event="Share" analytics-label="Twitter" href="">
        <span>Twitter</span>
    </a>

    <a class="btn btn-block btn-social-facebook" ng-click="share('facebook')" analytics-on="click" analytics-category="User Interaction" analytics-event="Share" analytics-label="Facebook" href="">
        <span>Facebook</span>
    </a>

    <a href="{{mailtoLink}}" class="btn btn-block btn-social-stumbleupon">
        <span>Email</span>
    </a>
</div>
