<form class="uploader form-group" target="factory-uploader-fallback" action="{{path}}" method="post" enctype="multipart/form-data" ng-class="{'uploader-legacy': !supported}">
    <div class="input-group btn-block">

        <input class="uploader-input" type="file" name="image" size="1" accept="image/*" title="Select file...">

        <div class="uploader-progress-bar" ng-hide="(status !== 'processing')"></div>

        <div class="input-group">
            <input class="form-control uploader-input-ghost" onfocus="this.blur()" type="text" value="{{fileName || 'Select file...'}}">
            <div class="input-group-btn">
                <a class="btn btn-primary uploader-btn">Browse</a>
            </div>
        </div>

    </div>

    <div class="text-info" ng-show="status === 'processing'">
        <div class="informative">Uploading file. Please wait.</div>
    </div>

    <div class="text-danger" ng-show="status === 'error'">
        <div class="informative">Problem occured while uploading file. Please try again.</div>
    </div>

    <div class="uploader-preview-container" ng-if="(file.hasValue())">
        <div class="uploader-preview" style="background-image: url('{{file.url()}}');"></div>
    </div>

    <div><small ng-if="(previewAdapter === 'canvas')">Preview for your uploaded image file is not available.</small></div>

    <iframe name="preview-uploader-fallback" src="about:blank" width="0" height="0"></iframe>

</form>
