<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="scrollable__container">
            <div class="scrollable__content">

                <div class="menu-gap-bottom">
                    <div>Decoration type</div>
                    <tile-list option-data="menuData.decorationTypes" tile-list-template="buttonList.html"></tile-list>
                </div>

                <div class="itemList">
                    <tile-list option-data="menuData.jerseyList" tile-type="standard" tile-list-template="templateList.html" tile-template="template"></tile-list>
                </div>
            </div>
        </div>
    </div>
</div>
