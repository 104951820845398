<div id="popup" class="modal-content modal-content-{{type}}">

    <div class="modal-header">
        <button type="button" class="close fui-cross" data-dismiss="modal" data-target="#lightbox"></button>
        <h4 class="modal-title">{{title}}</h4>
    </div>

    <div id="popupContent" class="modal-body modal-body-{{type}}" ng-transclude></div>

</div>
