<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="scrollable__container">
            <div class="scrollable__content">

                <roster roster-type="editable" roster-data-output="menuData.rosterSizes"></roster>

            </div>
        </div>
    </div>
</div>
