<div class="modal" ng-class="{'fade': !isMobile}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close fui-cross" data-dismiss="modal"></button>
                <h4 class="modal-title">{{orderSectionTitle}}&nbsp;<save-status></save-status></h4>
            </div>
            <div class="modal-body row">

                <div class="col-xs-12 col-sm-6 pull-right">

                    <div class="visible-xs-block">
                        <button class="btn btn-sm" ng-class="{'btn-primary': togglerState === 'closed', 'btn-warning': togglerState === 'open'}" ng-click="toggleTogglerTitle()">{{togglerTitle}}</button>
                        <hr ng-class="{'invisible': togglerState === 'open'}">
                    </div>

                    <design-summary class="collapse" ng-class="{'in': !isMobile || (isMobile && togglerState === 'open')}"></design-summary>

                    <hr ng-show="(isMobile && togglerState === 'open')">

                </div>

                <div class="col-xs-12 col-sm-6" contact-form>

                    <div ng-include="'orderDisclaimer.html'"></div>

                    <form
                        ng-class="{'blur grayscale': saveStatus === 'warning'}"
                        name="form"
                        ng-submit="activate();submitForm(form)"
                        analytics-on="submit" analytics-category="User Interaction" analytics-event="Contact Form" analytics-label="{{(formSubmitted && form.$invalid) ? 'Submit failed: errors in required fields' : 'Submitted successfully!'}}"
                        ng-if="(orderType === 'email')"
                        ng-hide="orderComplete||orderFailure"
                        ng-include="'orderForms/form.html'"
                        novalidate
                    ></form>
                    <p class="text-success" ng-if="(orderType === 'email')" ng-show="orderComplete" >A confirmation email was sent and a dealer will contact you soon. Thank you :)</p>
                    <p class="text-danger" ng-if="(orderType === 'email')" ng-show="orderFailure" >An error occurred during order. Please try again later.</p>
                </div>

            </div>
        </div>
    </div>
</div>
