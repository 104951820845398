<div mobile-check class="colors">

    <div class="color-group" ng-if="itemList.none.length">
        <div class="btn-group btn-group-vertical btn-block tile-list-color">
            <tile tile-template="color" ng-click="selectItem(itemCode)" x="{{itemCode}}" ng-repeat="itemCode in itemList.none"></tile>
        </div>
    </div>

    <div class="color-group" ng-if="itemList.colors.length">
        <div class="btn-group btn-group-vertical btn-block tile-list-color">
            <tile tile-template="color" ng-click="selectItem(itemCode)" ng-repeat="itemCode in itemList.colors"></tile>
        </div>
    </div>

    <div class="color-group" ng-if="itemList.camo.length">
        <div class="btn-group btn-group-vertical btn-block tile-list-color">
            <tile tile-template="color" ng-click="selectItem(itemCode)" ng-repeat="itemCode in itemList.camo"></tile>
        </div>
    </div>

</div>
