<div>
    <table class="roster table table-condensed" ng-if="(rosterType === 'printable')">

        <colgroup>
            <col style="width:10%;">
            <col style="width:10%;">
            <col style="width:10%;">
            <col style="width:70%;">
        </colgroup>

        <thead>
            <tr>
                <th>Size</th>
                <th>Qty.</th>
                <th>No.</th>
                <th>Jersey&nbsp;Name</th>
            </tr>
        </thead>

        <tbody>
            <tr ng-repeat="player in rosterDataOutputExtended">
                <td>{{sizeList.codeMap[player.size]}}</td>
                <td>{{player.qty}}</td>
                <td>{{player.number}}</td>
                <td>{{player.name}}</td>
            </tr>
        </tbody>

    </table>


    <div class="roster-edit" ng-if="(rosterType === 'editable')">
        <div class="row">
            <div class="col-roster-size">Size</div>
            <div class="col-roster-qty">Qty.</div>
            <div class="col-roster-nr">No.</div>
            <div class="col-roster-name">Jersey Name</div>
        </div>
        <div class="row" ng-repeat="player in rosterDataOutputExtended" ng-class="{'empty': ($index === (rosterDataOutputExtended.length - 1)), 'focused': isFocused}" type="editable" roster-row></div>
    </div>
</div>
