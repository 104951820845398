<div class="modal" ng-class="{'fade': !isMobile}">
    <div class="modal-dialog">
        <div class="modal-content" ng-class="{'loading': saveStatus === 'processing'}">
            <div class="modal-header">
                <button type="button" class="close fui-cross" data-dismiss="modal"></button>
                <h4 class="modal-title">{{shareSectionTitle}}&nbsp;<save-status></save-status></h4>
            </div>
            <div class="modal-body">
                <div ng-class="{'blur grayscale': saveStatus === 'warning'}">
                    <share-buttons></share-buttons>
                </div>
                <div class="form-group u-mt-1em">
                    <label for="saveInput">Your link</label>
                    <input id="saveInput" class="form-control" type="text" value="{{saveMessage}}" analytics-on="select" analytics-category="User Interaction" analytics-event="Selected Share URL">
                </div>
                <p>Anyone you share this URL with can view your jersey.</p>
            </div>
        </div>
    </div>
</div>
