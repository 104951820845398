<ul class="list-unstyled pull-right nav-controls">
    <li class="pull-left">
        <a toggle-class=".off-canvas:in,#togglePreview:active" id="togglePreview" class="btn btn-link visible-xs-block" analytics-on="click" analytics-category="User Interaction" analytics-event="Jersey Preview">
            <span class="icon-image"></span>
            <span class="nav-controls-label">Preview</span>
        </a>
    </li>
    <li class="pull-left">
        <a data-toggle="modal" data-target="#modal-summary"  class="btn btn-link" analytics-on="click" analytics-category="User Interaction" analytics-event="Design Summary">
            <span class="icon-edit"></span>
            <span class="nav-controls-label">Review</span>
        </a>
    </li>
    <li class="pull-left">
        <a data-toggle="modal" data-target="#modal-save" class="btn btn-link" analytics-on="click" analytics-category="User Interaction" analytics-event="Save & Share">
            <span class="icon-share-square"></span>
            <span class="nav-controls-label" ng-bind="shareSectionTitle">Save &amp; share</span>
        </a>
    </li>
    <li class="pull-left">
        <a data-toggle="modal" data-target="#modal-order" class="btn btn-link" analytics-on="click" analytics-category="User Interaction" analytics-event="Contact Me">
            <span class="icon-paper-plane"></span>
            <span class="nav-controls-label">Order</span>
        </a>
    </li>
</ul>
