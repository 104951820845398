<div class="col-roster-size input-group-sm">
    <select
        class="form-control form-control-sm"
        name="size"
        ng-options="size.code as size.name group by size.group for size in sizeList.itemMap"
        ng-model="player.size"
        ng-focus="onFocus()"
        ng-blur="onBlur()"
        ng-change="onChange()"
    ></select>
</div>
<div class="col-roster-qty input-group-sm">
    <input class="form-control" type="tel" name="qty" maxlength="3" pattern="[0-9]+" placeholder="1" ng-model="player.qty" ng-focus="onFocus()" ng-blur="onBlur()" ng-change="validateNumber('qty', player.qty)" ng-trim="false" novalidate>
</div>
<div class="col-roster-nr input-group-sm">
    <input class="form-control" type="tel" name="number" maxlength="3" pattern="[0-9]+" ng-model="player.number" ng-focus="onFocus()" ng-blur="onBlur()" ng-change="validateNumber('number', player.number)" ng-trim="false" novalidate>
</div>
<div mobile-check class="col-roster-name input-group-sm" ng-class="{'__col-xs-5': !isMobile, '__col-xs-4': isMobile}">
    <input class="form-control" type="text" name="name" ng-model="player.name" ng-focus="onFocus()" ng-blur="onBlur()" ng-change="onChange()">
</div>
<div mobile-check class="col-roster-remove input-group-sm text-center" ng-class="{'__col-xs-1': !isMobile, '__col-xs-2': isMobile}">
    <span class="roster-remove fui fui-cross text-danger" ng-click="removePlayer($index)" ng-show="($index < (rosterDataOutputExtended.length - 1))"></span>
</div>
