<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="menu__content__inner scrollable__container">
            <div class="scrollable__content">

                <div class="row menu-gap-bottom">
                    <div class="text-left col-xs-4">Enabled</div>
                    <div class="col-xs-8"><switch-tile option-data="menuData.location"></switch-tile></div>
                </div>

                <div class="form-group">
                    <text-input class="form-control" option-data="menuData.text" regexp="A-Z0-9\s" placeholder="Enter player name" ng-trim="false" ng-disabled="!designDataUtils.isPlayerNameEnabled()" debounce="500"></text-input>
                    <div class="informative">Only letters, numbers and spaces are allowed. <span ng-if="(previewAdapter === 'canvas')">Preview for the text you entered is not available.</span></div>
                </div>

                <div class="btn-group btn-group-vertical btn-block">

                    <menu-button menu-id="{{menuName}}Color" button-text="Color" ng-disabled="!designDataUtils.isPlayerNameEnabled()">
                        <color-mark source="playerName.color"></color-mark>
                    </menu-button>

                    <menu-button menu-id="{{menuName}}OutlineColor" button-text="Outline Color" ng-disabled="!designDataUtils.isPlayerNameEnabled()">
                        <color-mark source="playerName.outlineColor"></color-mark>
                    </menu-button>

                    <menu-button menu-id="{{menuName}}Font" button-text="Font" ng-disabled="!designDataUtils.isPlayerNameEnabled()">
                    </menu-button>

                    <menu-button menu-id="{{menuName}}Layout" button-text="Layout" ng-disabled="!designDataUtils.isPlayerNameEnabled()">
                    </menu-button>

                </div>

            </div>
        </div>
    </div>
</div>
