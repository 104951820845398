<ul class="breadcrumb palette-inverse">
    <li ng-repeat="item in menuData.breadcrumb">
        <a href="" class="" ng-click="changeMenu(item.id)">
            <span class="fui-home breadcrumb_root-menu-icon" ng-if="item.id === 'rootMenu'"></span>{{item.title}}
        </a>
    </li>
    <li class="active">
        <span class="fui-home breadcrumb_root-menu-icon" ng-if="menuData.id === 'rootMenu'"></span><span>{{menuData.titleDynamic || menuData.title}}</span>
    </li>
</ul>
