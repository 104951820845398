<div>
    <div class="save" ng-show="statusMessage">
        <div class="save__progress" ng-class="status">{{statusMessage}}</div>
    </div>

    <vendor-order-form></vendor-order-form>

    <div class="order" ng-class="{'blur overlay': formComplete && !orderComplete}" contact-form>
        <div class="order__form">
            <div class="order__form__right">
                <design-summary status="expanded"></design-summary>
            </div>

            <div class="order__form__left">
                <div class="order__form__designSummary">
                    <design-summary status="collapsed" togglable="true"></design-summary>
                </div>

                <div class="order__form__disclaimer" ng-include="'orderDisclaimer.html'"></div>

                <form class="form" name="form" ng-submit="submitForm(form)" analytics-on="submit" analytics-category="User Interaction" analytics-event="Contact Form" analytics-label="{{(formSubmitted && form.$invalid) ? 'Submit failed: errors in required fields' : 'Submitted successfully!'}}" ng-class="{'form_error': (formSubmitted && form.$invalid)}" ng-if="(orderType === 'email')" ng-hide="orderComplete" ng-include="formTemplate" novalidate></form>

                <div class="order__form__message" ng-if="(orderType === 'email')" ng-hide="!orderComplete" >A confirmation email was sent and a dealer will contact you soon. Thank you :)</p>
            </div>
        </div>
    </div>
</div>
