<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="row menu-gap-bottom" ng-if="designDataUtils.canHavePiping('sleeve')">
        <div class="text-left col-xs-4">Sleeve</div>
        <div class="col-xs-8"><switch-tile option-data="menuData.sleevePiping"></switch-tile></div>
    </div>
    <div class="row menu-gap-bottom" ng-if="designDataUtils.canHavePiping('placket')">
        <div class="text-left col-xs-4">Placket</div>
        <div class="col-xs-8"><switch-tile option-data="menuData.placketPiping"></switch-tile></div>
    </div>

    <div class="btn-group btn-group-vertical btn-block">

        <menu-button menu-id="{{menuName}}Color1" button-text="Color 1" ng-disabled="(!designDataUtils.hasPiping())">
            <color-mark source="piping.color1"></color-mark>
        </menu-button>

        <menu-button menu-id="{{menuName}}Color2" button-text="Color 2" ng-disabled="(!designDataUtils.hasPiping())">
            <color-mark source="piping.color2"></color-mark>
        </menu-button>

    </div>

</div>
