<div class="print-view">

    <p class="text-center">Marucci Baseball Jersey Customizer</p>

    <h4 class="text-center">Project Summary</h4>

    <div id="snapshots-disclaimer" class="text-center" ng-class="{'hidden-print': snapshots.length && printEnabled}">
        <h6 class="well">Please go to Summary<br>to save project and prepare it for print</h6>
    </div>

    <table class="table table-condensed" ng-class="{'hidden-print': !snapshots.length || !printEnabled}">
        <tr>
            <th>Project ID</th>
            <td>{{urlService.getProjectID()}}</td>
        </tr>
        <tr>
            <th>Project Link</th>
            <td>{{urlService.getShareUrl(true)}}</td>
        </tr>
    </table>

    <div id="snapshots-list" class="break-after row" ng-class="{'hidden-print': !snapshots.length || !printEnabled}">
        <div class="row">
            <img class="col-xs-6" ng-src="{{item.imgUrl}}" ng-repeat="item in snapshots"/>
        </div>
        <div class="text-center">Actual colors on product may vary.</div>
    </div>


    <design-summary ng-class="{'hidden-print': !snapshots.length || !printEnabled}"></design-summary>

</div>
