<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="menu__content__inner scrollable__container">
            <div class="scrollable__content">

                <tile-list class="menu-gap-bottom" option-data="menuData.location" tile-list-template="buttonList.html"></tile-list>

                <div class="alert alert-warning"><div class="informative">“Team Name” is overlapping with “<a href="" ng-click="changeMenu('frontLogo')">Front Logo</a>” &ndash; they can not be both selected at the same time, you can turn ON only one of them.</div></div>

                <div class="form-group">
                    <text-input class="form-control" option-data="menuData.text" regexp="A-Z0-9\s" placeholder="Teamname" ng-trim="false" ng-disabled="!designDataUtils.isTeamNameEnabled()" debounce="500"></text-input>
                    <div class="informative">Only letters, numbers and spaces are allowed. <span ng-if="(previewAdapter === 'canvas')">Preview for the text you entered is not available.</span></div>
                </div>

                <div class="btn-group btn-group-vertical btn-block">

                    <menu-button menu-id="{{menuName}}Color" button-text="Color" ng-disabled="!designDataUtils.isTeamNameEnabled()"">
                        <color-mark source="teamName.color"></color-mark>
                    </menu-button>

                    <menu-button menu-id="{{menuName}}OutlineColor" button-text="Outline Color" ng-disabled="!designDataUtils.isTeamNameEnabled()">
                        <color-mark source="teamName.outlineColor"></color-mark>
                    </menu-button>

                    <menu-button menu-id="{{menuName}}Font" button-text="Font" ng-disabled="!designDataUtils.isTeamNameEnabled()">
                    </menu-button>

                    <menu-button menu-id="{{menuName}}Layout" button-text="Layout" ng-disabled="!designDataUtils.isTeamNameEnabled()">
                    </menu-button>

                    <menu-button menu-id="tailing" button-text="Tailing" ng-disabled="!designDataUtils.hasLegalValues('teamName.tail.type') || !designDataUtils.isTeamNameEnabled()">
                    </menu-button>

                </div>

            </div>
        </div>
    </div>
</div>
