<div id="lightbox" class="modal" ng-switch="popupName">

    <!--<div class="lightbox__wrapper" ng-switch-when="print">-->
        <!--<div class="lightbox__wrapper">-->
            <!--<popup ng-if="(popupName === 'print')" type="print">-->
                <!--<print></print>-->
            <!--</popup>-->
        <!--</div>-->
    <!--</div>-->

    <div class="modal-dialog" ng-switch-default>

            <!--<popup ng-if="(popupName === 'order')" title="{{orderSectionTitle}}">-->
                <!--<order></order>-->
            <!--</popup>-->

            <!--<popup ng-if="(popupName === 'share')" title="{{shareSectionTitle}}">-->
                <!--<share></share>-->
            <!--</popup>-->

            <!--<popup ng-if="(popupName === 'warning')" title="Small screen :(">-->
                <!--<warning></warning>-->
            <!--</popup>-->

        <div class="modal-content modal-content-order" ng-if="popupName === 'order'">

            <div class="modal-header">
                <button type="button" class="close fui-cross" data-dismiss="modal"></button>
                <h4 class="modal-title">{{orderSectionTitle}}</h4>
            </div>

            <div class="modal-body modal-body-order">
                <order></order>
            </div>

        </div>

        <div class="modal-content modal-content-share" ng-if="popupName === 'share'">

            <div class="modal-header">
                <button type="button" class="close fui-cross" data-dismiss="modal"></button>
                <h4 class="modal-title">{{shareSectionTitle}}</h4>
            </div>

            <div class="modal-body modal-body-share">
                <share></share>
            </div>

        </div>


    </div>

</div>
