<div>
    <table class="roster table table-condensed">
        <colgroup>
            <col style="width:30%">
            <col style="width:70%">
        </colgroup>

        <thead>
            <tr>
                <th>Size</th>
                <th>Quantity</th>
            </tr>
        </thead>

        <tbody>
            <tr ng-repeat="sumEntry in summary">
                <td>{{sumEntry.name}}</td>
                <td>{{sumEntry.qty}}</td>
            </tr>
        </tbody>

        <tfoot>
            <tr>
                <th>Total</th>
                <th>{{total}}</th>
            </tr>
        </tfoot>
    </table>
</div>
