<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="row menu-gap-bottom">
        <div class="text-left col-xs-4">Front</div>
        <div class="col-xs-8"><switch-tile option-data="menuData.frontLocation"></switch-tile></div>
    </div>

    <div class="row menu-gap-bottom">
        <div class="text-left col-xs-4">Back</div>
        <div class="col-xs-8"><switch-tile option-data="menuData.backLocation"></switch-tile></div>
    </div>

    <div class="form-group">
        <text-input class="form-control" option-data="menuData.number" regexp="\d" maxlength="2" placeholder="Your number" debounce="500" ng-disabled="!designDataUtils.isNumberEnabled()"></text-input>
        <div class="informative" ng-if="(previewAdapter === 'canvas')">Preview for “Number” text is not available.</div>
    </div>

    <div class="btn-group btn-group-vertical btn-block">

        <menu-button menu-id="{{menuName}}Color" button-text="Color" ng-disabled="!designDataUtils.isNumberEnabled()">
            <color-mark source="number.color"></color-mark>
        </menu-button>

        <menu-button menu-id="{{menuName}}OutlineColor" button-text="Outline Color" ng-disabled="!designDataUtils.isNumberEnabled()">
            <color-mark source="number.outlineColor"></color-mark>
        </menu-button>

        <menu-button menu-id="{{menuName}}Font" button-text="Font" ng-disabled="!designDataUtils.isNumberEnabled()">
        </menu-button>
    </div>

</div>
