<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="scrollable__container">
            <div class="scrollable__content">

                <tile-list class="menu-gap-bottom" option-data="menuData.location" tile-template="button" tile-list-template="buttonList.html" ng-if="menuData.id !== 'sleeveLogo'"></tile-list>

                <div class="alert alert-warning" ng-if="menuData.id === 'frontLogo'">
                    <div class="informative">
                        “Front Logo” is overlapping with “<a href="" ng-click="changeMenu('teamName')">Team Name</a>”
                        &ndash; they can not be both selected at the same time, you can turn ON only one of them.
                    </div>
                </div>

                <div class="row menu-gap-bottom" ng-if="menuData.id === 'sleeveLogo'">
                    <div class="text-left col-xs-4">Enabled</div>
                    <div class="col-xs-8"><switch-tile option-data="menuData.location"></switch-tile></div>
                </div>

                <div>
                    <div class="menu__content__title">Preview image</div>
                    <uploader ng-init="isMobile = isMobile" template="previewImage" file="menuData.previewFile"></uploader>
                </div>
                <div>
                    <div class="menu__content__title">Hi-Res file</div>
                    <uploader template="factoryImage" file="menuData.factoryFile"></uploader>
                </div>

            </div>
        </div>
    </div>
</div>
