<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="btn-group btn-group-vertical btn-block">
        <menu-button menu-id="{{submenu.id}}" button-text="{{submenu.titleDynamic || submenu.title}}" ng-repeat="submenu in menuData.submenuList" ng-if="!submenu.disabled">
            <color-mark source="{{submenu.colors.$path}}"></color-mark>
        </menu-button>
    </div>
</div>
