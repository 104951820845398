<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="scrollable__container">
            <div class="scrollable__content">

                <div class="row menu-gap-bottom">
                    <div class="text-left col-xs-4">Enabled</div>
                    <div class="col-xs-8"><switch-tile option-data="menuData.enabled"></switch-tile></div>
                </div>

                <div class="menu__content__title">Tail type</div>

                <tile-list option-data="menuData.tails" tile-name="tailType" tile-scale="3x1" text-position="bottom" tile-columns="double"></tile-list>

                <div class="" ng-if="(previewAdapter === 'canvas')">
                    <div class="informative">Preview for “Tailing” text, color and font is not available.</div>
                </div>

                <div class="form-group">
                    <text-input class="form-control" option-data="menuData.text" placeholder="Tail text" regexp="A-Z0-9\s" novalidate="novalidate" ng-disabled="!designDataUtils.isTailingEnabled()" debounce="500"></text-input>
                    <div class="informative">Only letters, numbers and spaces are allowed.</div>
                </div>


                <div class="btn-group btn-group-vertical btn-block">

                    <menu-button menu-id="{{menuName}}Color" button-text="Text color" ng-disabled="!designDataUtils.isTailingEnabled()">
                        <color-mark source="teamName.tail.color"></color-mark>
                    </menu-button>

                    <menu-button menu-id="{{menuName}}Font" button-text="Text font" ng-disabled="!designDataUtils.isTailingEnabled()">
                    </menu-button>

                </div>

            </div>
        </div>
    </div>
</div>
