<div class="design-summary">

    <div>
        <div>Design</div>
        <table class="table table-condensed">
            <tr>
                <th>Jersey</th>
                <td>{{designData.jersey.text()}}</td>
            </tr>
            <tr>
                <th>Decoration type</th>
                <td>{{designData.decoType.text()}}</td>
            </tr>
            <tr>
                <th>Design</th>
                <td>{{designData.design.text()}}</td>
            </tr>
        </table>
    </div>

    <div>
        <div>Colors</div>
        <table class="table table-condensed">
            <tr ng-repeat="submenu in menuDataMap.colors.submenuList" ng-if="!submenu.disabled">
                <th>{{submenu.titleDynamic || submenu.title}}</th>
                <td>{{submenu.colors.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designData.teamName.location.value() !== 'none' && designData.teamName.text.hasValue())">
        <div>Team Name</div>
        <table class="table table-condensed">
            <tr>
                <th>Position</th>
                <td>{{designData.teamName.location.text()}}</td>
            </tr>
            <tr>
                <th>Text</th>
                <td>{{designData.teamName.text.text()}}</td>
            </tr>
            <tr>
                <th>Color</th>
                <td>{{designData.teamName.color.text()}}</td>
            </tr>
            <tr>
                <th>Outline Color</th>
                <td>{{designData.teamName.outlineColor.text()}}</td>
            </tr>
            <tr>
                <th>Font</th>
                <td>{{designData.teamName.font.text()}}</td>
            </tr>
            <tr>
                <th>Layout</th>
                <td>{{designData.teamName.layout.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="((designData.teamName.location.value() !== 'none') && (designData.teamName.tail.type.haslegalValues()))">
        <div>Team Name Tailing</div>
        <table class="table table-condensed">
            <tr>
                <th>Type</th>
                <td>{{designData.teamName.tail.type.text()}}</td>
            </tr>
            <tr ng-if="(designData.teamName.tail.text.hasValue())">
                <th>Text</th>
                <td>{{designData.teamName.tail.text.text()}}</td>
            </tr>
            <tr ng-if="(designData.teamName.tail.text.hasValue())">
                <th>Color</th>
                <td>{{designData.teamName.tail.color.text()}}</td>
            </tr>
            <tr ng-if="(designData.teamName.tail.text.hasValue())">
                <th>Font</th>
                <td>{{designData.teamName.tail.font.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designDataUtils.hasPiping())">
        <div>Piping</div>
        <table class="table table-condensed">
            <tr>
                <th>Sleeve</th>
                <td>{{designData.piping.sleevePiping.text()}}</td>
            </tr>
            <tr>
                <th>Placket</th>
                <td>{{designData.piping.placketPiping.text()}}</td>
            </tr>
            <tr>
                <th>Color 1</th>
                <td>{{designData.piping.color1.text()}}</td>
            </tr>
            <tr>
                <th>Color 2</th>
                <td>{{designData.piping.color2.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designData.frontLogo.location.value() !== 'none' && designData.frontLogo.previewFile.value() && designData.frontLogo.factoryFile.value())">
        <div>Front logo&thinsp;</div>
        <table class="table table-condensed">
            <tr>
                <th>Position</th>
                <td>{{designData.frontLogo.location.text()}}</td>
            </tr>
            <tr>
                <th>Preview file</th>
                <td ng-if="(designData.frontLogo.previewFile.hasValue())">
                    <a href="{{designData.frontLogo.previewFile.url()}}" target="_blank">
                        {{(printable === 'true') ? designData.frontLogo.previewFile.url() : designData.frontLogo.previewFile.path()}}
                    </a>
                </td>
                <td ng-if="(!designData.frontLogo.previewFile.hasValue())">&mdash;</td>
            </tr>
            <tr>
                <th>Hi-Res file</th>
                <td ng-if="(designData.frontLogo.factoryFile.hasValue())">
                    <a href="{{designData.frontLogo.factoryFile.url()}}" target="_blank">
                        {{(printable === 'true') ? designData.frontLogo.factoryFile.url() : designData.frontLogo.factoryFile.path()}}
                    </a>
                </td>
                <td ng-if="(!designData.frontLogo.factoryFile.hasValue())">&mdash;</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designData.sleeveLogo.location.value() !== 'none' && designData.sleeveLogo.previewFile.value() && designData.sleeveLogo.factoryFile.value())">
        <div>Sleeve logo&thinsp;</div>
        <table class="table table-condensed">
            <tr>
                <th>Position</th>
                <td>{{designData.sleeveLogo.location.text()}}</td>
            </tr>
            <tr>
                <th>Preview file</th>
                <td ng-if="(designData.sleeveLogo.previewFile.hasValue())">
                    <a href="{{designData.sleeveLogo.previewFile.url()}}" target="_blank">
                        {{(printable === 'true') ? designData.sleeveLogo.previewFile.url() : designData.sleeveLogo.previewFile.path()}}
                    </a>
                </td>
                <td ng-if="(!designData.sleeveLogo.previewFile.hasValue())">&mdash;</td>
            </tr>
            <tr>
                <th>Hi-Res file</th>
                <td ng-if="(designData.sleeveLogo.factoryFile.hasValue())">
                    <a href="{{designData.sleeveLogo.factoryFile.url()}}" target="_blank">
                        {{(printable === 'true') ? designData.sleeveLogo.factoryFile.url() : designData.sleeveLogo.factoryFile.path()}}
                    </a>
                </td>
                <td ng-if="(!designData.sleeveLogo.factoryFile.hasValue())">&mdash;</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designData.playerName.location.value() !== 'none')">
        <div>Player Name</div>
        <table class="table table-condensed">
            <tr>
                <th>Color</th>
                <td>{{designData.playerName.color.text()}}</td>
            </tr>
            <tr>
                <th>Outline Color</th>
                <td>{{designData.playerName.outlineColor.text()}}</td>
            </tr>
            <tr>
                <th>Font</th>
                <td>{{designData.playerName.font.text()}}</td>
            </tr>
            <tr>
                <th>Layout</th>
                <td>{{designData.playerName.layout.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designDataUtils.hasNumber())">
        <div>Numbering</div>
        <table class="table table-condensed">
            <tr>
                <th>Front</th>
                <td>{{designData.number.frontLocation.text()}}</td>
            </tr>
            <tr>
                <th>Back</th>
                <td>{{designData.number.backLocation.text()}}</td>
            </tr>
            <tr>
                <th>Font</th>
                <td>{{designData.number.font.text()}}</td>
            </tr>
            <tr>
                <th>Color</th>
                <td>{{designData.number.color.text()}}</td>
            </tr>
            <tr>
                <th>Outline Color</th>
                <td>{{designData.number.outlineColor.text()}}</td>
            </tr>
        </table>
    </div>

    <div ng-if="(designDataUtils.hasRosterData())">
        <div>Roster</div>
        <roster roster-type="printable"></roster>

        <div>Roster Size Summary</div>
        <roster-size-summary></roster-size-summary>
    </div>

    <p class="text-danger hidden-print">Actual colors on product may vary.</p>

</div>
