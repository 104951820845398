<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable" >
        <div class="scrollable__container">
            <div class="scrollable__content">

                <design-summary status="expanded" togglable="false"></design-summary>

            </div>
        </div>
    </div>
</div>
