<div class="menu__content">

    <breadcrumb></breadcrumb>

    <div class="scrollable">
        <div class="scrollable__container">
            <div class="scrollable__content">
                <div class="itemList">
                    <tile-list option-data="menuData.designList" tile-type="standard" tile-list-template="templateList.html" tile-template="design"></tile-list>
                </div>
            </div>
        </div>
    </div>
</div>
