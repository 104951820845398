<div id="viewSwitcher" class="previewArea__viewSwitcher" ng-if="(previewAdapter === 'canvas')">
    <div class="previewArea__viewSwitcher__inner">
<!--
        <div class="navigation__button_zoom button button_squared" ng-click="zoomState ? zoomOut() : zoomIn()">
            <i class="icon icon_zoom" ng-class="{'icon_zoom_zoomOut': zoomState, 'icon_zoom_zoomIn': !zoomState}"></i>
        </div>
//-->
        <ul>
            <li ng-repeat="view in viewList">
                <button class="button button_block" ng-class="{'selected': (view.code === activeView)}" ng-click="changeView(view.code)">{{view.name}} <ins>view</ins></button>
            </li>
        </ul>
    </div>
</div>
